<template>
    <div class="msp-misurazione-inputnumber" :class="inputContainerClass">
        <div class="last" v-if="last && !content" v-html="formatLast(last)" />
             <v-btn v-if="content" plain @click="removeValue();clearInput()"><v-icon>mdi-delete</v-icon></v-btn>
        <div class="input">
            <input
                size=3
                :max="max"
                :min="min"
                :step="step"
                @change="onChange"
                @input="adjustInput"
                type="number"
                v-model="content"
                @focus="select"
                @blur="resetInput"
                :style="inputStyle"
                :placeholder="myPlaceholder"
            />
            <div class="msp-misurazione-inputnumber__label" v-if="label" v-text="label"></div>
        </div>
        <div v-show="alert.show">
            <v-alert class="mb-0" v-model="alert.show" type='error'>{{alert.text}}</v-alert>
            <v-progress-linear
                :value="alert.progress">
            </v-progress-linear>
        </div>
    </div>
</template>
<script>
import Commons from "./Commons.vue";
import _funzioni from "../../../../js/msp/funzioni.js"
export default {
    extends: Commons,
    props: {
        'min': {
            type: Number,
            default: 0,
        },
        'max': {
            type: Number,
            default: 10,
        },
        'label': {
            type: String,
            default: null,
        },
        'step': {
            type: Number,
            default: 1,
        },
        'placeholder': {
            type: String,
            default: '0',
        },
    },
    data: function () {
        return {
            alert: {
                show: false,
                time: 0,
                duration: 10,
                progress: 100,
                speed: 200,
                text: '',
            },
            oldValue: null,
            myPlaceholder: "",
        }
    },
    computed: {
        inputStyle: function () {
            let base;
            if (!this.content) {
                base = 2;
            } else {
                base = String(this.content).length;
            }

            let width = base + 2;
            return  '--width:' + width + 'em;';
        }
    },
    watch: {
        placeholder: function (nv) {
            this.myPlaceholder = nv;
        },
    },
    methods: {
        normalizeComma: function (value) {
            if (!value) return;
            value = String(value);
            return value.replace(",",".");
        },
        onChange: function (ev) {
            this.adjustInput(ev);
            this.validateInput(ev);
            this.handleInput(this.content);
        },
        showAlert: function (text) {
            this.alert.text = text;
            this.alert.time = this.alert.duration;
            this.alert.show = true;
            this.decreaseAlertTime();
        },
        resetAlert: function () {
                this.alert.show= false;
                this.alert.time= this.alert.duration;
                this.alert.progress= 100;
                this.alert.text= '';
        },
        decreaseAlertTime: function () {
            if (this.alert.time <= 0) {
                this.resetAlert();
                return;
            }
            this.alert.time -= 1;
            this.alert.progress = this.alert.time * 100 / this.alert.duration;
            setTimeout(this.decreaseAlertTime, this.alert.speed);
        },
        hideAlert: function () {
            this.alert = null;
        },
        validateInput: function (ev) {
            if (this.content > this.max) {
                this.content = this.max;
                this.showAlert('Valore massimo');
            }
            if (this.content < this.min) {
                this.content = this.min;
                this.showAlert('Valore minimo');
            }
            ev.target.value = this.content;
        },
        adjustInput: function (ev) {
            if (!this.content) {
                this.clearInput();
                return;
            }
            this.content = this.normalizeComma(this.content);
            const digits = 1;
            this.content = _funzioni.decimal(this.content, digits);
            ev.target.value = this.content;
        },
        resetInput: function () {
            if (!this.content) {
                this.content = this.oldContent;
            }
        },
        clearInput: function () {
            this.oldContent = this.content;
            this.content = null;
        },
        select: function () {
            this.clearInput();
            this.myPlaceholder = "";
        },
    }
};
</script>
<style  lang="scss">
.msp-misurazione-inputnumber {
    .input {
        display: flex;
    align-items: center;
    --width: 3em;
    gap: 1em;
    --bg-color: var(--col-grigio-chiarissimo);
}
    input {
        text-align: center;
        font-size: 2em;
        background: var(--bg-color);
        width: var(--width);
        padding: 0.5em;
    }
    &__label {
        font-size: 2em;
    }
}
</style>
