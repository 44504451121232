import rpe from '@cached/rpe.json';
import ums from '@cached/um.json';
import trimps from '@cached/trimps.json';
import valutazioni from '@cached/valutazione_allenamento.json';
import percentuali from '@cached/percentuali.json';
import attivita from '@cached/attivita.json';
import sport from '@cached/sport.json';
import ruoli from '@cached/ruolo.json';
import tipoTests from '@cached/tipoTests.json';
const MSP = {}
/** unità di misura da database
* tabella: t_ana_um
*/
MSP.FRONTPAGE = "index.php";

MSP.UMS = ums.reduce((acc,el)=>{
acc[el.codice] = el.id;
    return acc;
},{});

MSP.CONVERSIONI = ums.reduce((acc,el)=>{
acc[el.codice] = el.conversione;
    return acc;
},{});

MSP.ZONE = {
  "REC": 15,
},

MSP.RPE = rpe;
MSP.TRIMPS = trimps;
MSP.VALUTAZIONI = valutazioni;
MSP.PERCENTUALI = percentuali;
MSP.ATTIVITA = attivita;
MSP.SPORT = sport;
MSP.RUOLI = ruoli;
MSP.TIPO_TESTS = tipoTests;

MSP.SPORT_ABILITATI = MSP.SPORT.filter(el=>(+el.abilitato && +el.praticato)).map(el=>+el.id);
MSP.sport_id = MSP.SPORT.reduce((acc,el)=>{
    acc[el.codice] = +el.id;
    return acc;
},{});

MSP.sport_id = {};
MSP.SPORT.forEach((el)=>{
    MSP['ID_'+(el.codice.toUpperCase())] = +el.id;
    MSP.sport_id[el.codice] = +el.id;
});


MSP.SUCCESS_FALSE = {
    success: false,
    status: 304
}

export {MSP as default};
